import React from "react";
import { graphql } from "gatsby";
import { RichText, Date } from "prismic-reactjs";
import styled from "@emotion/styled";
import CommonLayout from "../components/layout/CommonLayout";
import SEO from "../components/seo";
import { useTranslation } from "gatsby-plugin-react-i18next";

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
`;

const Container = styled.div`
  margin: 0.5em 1em;
  .wrapper-image {
    position: relative;
    height: 0;
    padding-bottom: 24.86%;
    width: 100%;
    @media (max-width: 600px) {
      padding-bottom: 52.66%;
    }
    & img {
      position: absolute;
      border-radius: 4px;
      top: 0;
      left: 0;
      right: 0;
      @media (max-width: 600px) {
        border-radius: 4px 4px 0px 0px;
      }
    }
  }

  & a {
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    @media (max-width: 600px) {
      gap: 0.5em;
      flex-direction: column;
    }
    font-size: clamp(1.3rem, 2.5vw, 1.5rem);
    text-decoration: none;
    .header {
      font-size: clamp(1rem, 2.5vw, 1.2rem);
      color: #000;
      font-weight: 600;
    }
    :hover .header {
      color: #fbb400;
      text-decoration: underline;
    }
  }
  .wrapper-text {
    width: 100%;
    padding: 0em 0.5em;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
  }
  .description {
    font-size: clamp(0.9rem, 2.5vw, 0.9rem);
    color: rgb(64, 64, 64);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const CategoryApp = (props) => {
  const {
    data: { posts },
    pageContext: { name },
  } = props;
  const { t } = useTranslation();

  return (
    <CommonLayout>
      <SEO title={`${name} (app)`} />
      <div
        style={{
          maxWidth: 700,
          width: "100%",
          margin: "1em auto",
        }}
      >
        <React.Fragment>
          <div
            style={{
              padding: "0.6em 0.4em",
              fontSize: "2rem",
              fontWeight: 600,
              borderRadius: 4,
              textTransform: "uppercase",
            }}
          >
            <strong style={{ color: "#fbb400", marginRight: "0.4em" }}>
              {t("app")}
            </strong>
            {t(`${props.pageContext.name.replace("-", " ")}`)}
          </div>
        </React.Fragment>
        <React.Fragment>
          {posts.nodes.length !== 0 &&
            posts.nodes.map((object) => (
              <CardWrapper key={object.id}>
                <Container>
                  <a
                    // replace https://app-support.kuupixmaker.ai/ with / for localhost
                    href={`https://app-support.kuupixmaker.ai/${props.pageContext.lang}/mobile/${object.uid}`}
                  >
                    {object.data.thumbnail.url !== null && (
                      <div className="wrapper-image">
                        <img
                          loading="lazy"
                          src={object.data.thumbnail.url}
                          alt={object.data.thumbnail.alt}
                          width="100%"
                          height="auto"
                        />
                      </div>
                    )}

                    <div className="wrapper-text">
                      <div className="header">
                        {RichText.asText(object.data.title.raw).length !== 0 &&
                          RichText.asText(object.data.title.raw)}
                      </div>
                      <div className="description">
                        {RichText.asText(object.data.description.raw).length !==
                          0 && RichText.asText(object.data.description.raw)}
                      </div>
                      <div
                        style={{
                          color: "rgba(117, 117, 117, 1)",
                          padding: "0.3em 0em",
                          fontSize: "0.75rem",
                        }}
                      >
                        {/* <time>
                          {new Intl.DateTimeFormat("en-US", {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                          }).format(Date(object.data.date))}
                        </time> */}
                      </div>
                    </div>
                  </a>
                </Container>
              </CardWrapper>
            ))}
        </React.Fragment>
      </div>
    </CommonLayout>
  );
};

export default CategoryApp;

export const query = graphql`
  query ($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    posts: allPrismicPostApp(
      sort: { fields: [last_publication_date], order: DESC }
      filter: {
        data: {
          categories: {
            elemMatch: {
              category: { lang: { eq: $language }, slug: { eq: $slug } }
            }
          }
        }
      }
    ) {
      nodes {
        id
        uid
        url
        lang
        data {
          title {
            raw
          }
          description {
            raw
          }
          date
          thumbnail {
            url
            dimensions {
              height
              width
            }
            alt
          }
          categories {
            category {
              id
              document {
                ... on PrismicCategory {
                  data {
                    name
                  }
                }
              }
            }
          }
          body {
            ... on PrismicPostAppBodyBannerWithCaption {
              id
              slice_label
              slice_type
              primary {
                image_banner {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
                description {
                  raw
                }
              }
            }
          }
        }
      }
    }
  }
`;
